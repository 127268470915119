import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import Announcement from "@material-ui/icons/Announcement";
import {PlayCircleFilledOutlined,ContactSupport} from '@material-ui/icons';
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { makeStyles } from "@material-ui/core/styles";
import { Category } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
	icon: {
		color: theme.palette.secondary.main
	},
}));
function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );
  const classes = useStyles();

  return (
    <li>
      <ListItem button component={renderLink} className={className}>
      {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  const { drawerClose } = props;
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const classes = useStyles();


  return (
    <div onClick={drawerClose}>
      
          {/*<li>
          <ListItem button onClick={()=>window.open("https://www.myleads.com.br/instalar")}>
      <ListItemIcon className={classes.icon}><ContactSupport/></ListItemIcon>
        <ListItemText primary={"INSTALAR (OFICIAL)"} />
      </ListItem>
    </li>
    /*<li>
       <ListItem button onClick={()=>window.open("https://www.myleads.com.br/betainstall")}>
      <ListItemIcon className={classes.icon}><ContactSupport/></ListItemIcon>
        <ListItemText primary={"INSTALAR (BETA)"} />
      </ListItem>
  </li>
          <li>
          <ListItem button onClick={()=>window.open("https://www.myleads.com.br/tutoriais")}>
      <ListItemIcon className={classes.icon}><PlayCircleFilledOutlined/></ListItemIcon>
        <ListItemText primary={"Tutoriais"} />
      </ListItem>
    </li>
    <li>
          <ListItem button onClick={()=>window.open("https://myleads.com.br/suporte")}>
      <ListItemIcon className={classes.icon}><ContactSupport/></ListItemIcon>
        <ListItemText primary={"Suporte"} />
      </ListItem>
    </li>*/}
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            <ListSubheader inset>
              {"Admin"}
            </ListSubheader>
            <ListItemLink
              to="/users"
              primary={"Clientes"}
              icon={<PeopleAltOutlinedIcon />}
            />
            <ListItemLink
              to="/planos"
              primary={"Planos"}
              icon={<Category />}
            />
            {/*<ListItemLink
              to="/cafelindo"
              primary={"Sugestões"}
              icon={<Category />}
        />*/}
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;