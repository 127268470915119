import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";



import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  greetingMessage: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  custom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const PlanoSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
    multidevice: Yup.number().integer('Digite apenas números')
    
    .required(i18n.t("login.plano.required")),
    numbers: Yup.number().integer('Digite apenas números')
    
    .required(i18n.t("login.plano.required")),
});

const PlanoModal = ({ open, onClose, planoId, onEdit ,reload}) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    numbers: "",
    multidevice: "",
  };
  const [plano, setPlano] = useState(initialState);
  const greetingRef = useRef();
  const [selectedPlano, setSelectedPlano] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (!planoId) return;
      try {
        const { data } = await api.get(`/plano/${planoId}`);
        setPlano((prevState) => {
          return { ...prevState, ...data,numbers:data.numbers };
        });
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setPlano({
        name: "",
        multidevice: "",
        numbers: "",
      });
    };
  }, [planoId, open]);



  const handleClose = () => {
    onClose();
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedPlano(null);
  };

  const handleDeletePlano = async (optionsId) => {
    try {
      const { data } = await api.get(`/plano/${planoId}`);
      setPlano(initialState);
      setPlano(data);
      toast.success(i18n.t("Plano deleted successfully!"));
      if (typeof reload == 'function') {
        reload();
    }
    } catch (err) {
      toastError(err);
    }
  };

  const handleSavePlano = async (values) => {
    try {
      if (planoId) {
        await api.put(`/plano/${planoId}`, {...values,numbers:values.numbers-1});
      } else {
        await api.post("/plano", values);
      }

      toast.success("Plano saved successfully");
      if (typeof reload == 'function') {
        reload();
    }
      handleClose();

    } catch (err) {
      toastError(err);
    }
  };





  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={
          selectedPlano &&
          `${i18n.t("planos.confirmationModal.deleteTitle")} ${
            selectedPlano.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeletePlano(selectedPlano.id)}
      >
        {i18n.t("Tem certeza? Todas as opções internas também serão excluídas")}
      </ConfirmationModal>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle>
          {planoId
            ? `${i18n.t("planoModal.title.edit")}`
            : `${i18n.t("planoModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={plano}
          validateOnChange={false}
          enableReinitialize={true}
          validationSchema={PlanoSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSavePlano(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ handleChange, touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t("planoModal.form.name")}
                  autoFocus
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                />
              <div className={classes.multFieldLine}>
            <Field
                  as={TextField}
                  label={i18n.t("planoModal.form.multidevice")}
                  autoFocus
                  name="multidevice"
                  error={touched.multidevice && Boolean(errors.multidevice)}
                  helperText={touched.multidevice && errors.multidevice}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                /></div>  
                <div className={classes.multFieldLine}>
                <Field
                  as={TextField}
                  label={i18n.t("planoModal.form.numbers")}
                  autoFocus
                  name="numbers"
                  error={touched.numbers && Boolean(errors.numbers)}
                  helperText={touched.numbers && errors.numbers}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  fullWidth
                />
                </div>  
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  // color="secondary"
                  disabled={isSubmitting}
                  // variant="outlined"
                >
                  {i18n.t("planoModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {planoId
                    ? `${i18n.t("planoModal.buttons.okEdit")}`
                    : `${i18n.t("planoModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default PlanoModal;
