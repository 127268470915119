const messages = {
  pt: {
    translations: {
      planos:{
        title:"Planos",
        table:{
          name:"Nome",
          multidevice:"Números",
          numbers:"Sessões",
          actions:"Ações"
        }
      },
      planoModal:{
        buttons:{cancel:"Cancelar",okEdit:"Editar",okAdd:"Adicionar"},
        title:{
          add:"Adicionar Plano",
          edit:"Editar Plano"
        },
        form:{
          name:"Nome",
          multidevice:"Sessões",
          numbers:"Números",
        }
      },
      contacts:{searchPlaceholder:"Pesquisar"},
      users:{
        name:"Nome",
        title:"Clientes",
        table:{
          email:"E-mail",
          number:"Contato",
          affiliated:"Afiliado",
          name:"Nome",
          plano:"Plano",
          expire:"Validade",
          actions:"Ações",
          noplan:"Sem Plano",
          nonumber:"Sem Número",
          noaffiliated:"Sem afiliação",
          expired:"Expirado"
        }
      },
      layout:{
        noplan:"LICENÇA EXPIRADA, RENOVE SEU PLANO",
        plan:"Plano: ",
        planon:" | Válido até: "
      },
      confirmationModal:{
        buttons:{
          cancel:"Cancelar",
          confirm:"Confirmar"
        }
      },
      numberrs:{
        confirmationModal:{
          deleteTitle:"Você deseja remover a licença para este número?",
          deleteMessage:"ATENÇÃO: O NÚMERO SERÁ DESCONECTADO",
          buttons:{
            cancel:"Cancelar",
            confirm:"Confirmar"
          }
        },
        CONNECTED:"Conectado",
        DISCONNECTED:"Offline",
        title:"Números Autorizados",
        table:{
          number:"Números",
          actions:"Ações",
          status:"Status"
        }
      },
      numberrModal:{
        buttons:{cancel:"Cancelar",okEdit:"Editar",okAdd:"Adicionar"},
        title:{
          add:"Adicionar Número",
          edit:"Editar Número"
        },
        form:{
          number:"Número"
        }
      },
      login:{
        number:"Número de Contato",
        numberr:{
          required:"Obrigatório"
        },
        password:"Senha",
        button:"Logar",
        name:'Nome e sobrenome',
        registror:"Registrar",
        registro:"Não tem um conta? Cadastre-se!",
        reset:{
          success:"Sua senha foi salva com sucesso! Agora você pode utilizar sua nova senha para acessar sua conta.",
          envio:"O e-mail de recuperação foi enviado endereço de e-mail fornecido. Por favor, verifique sua caixa de entrada e siga as instruções fornecidas para concluir o processo de recuperação da conta."
        },
        login:"Já tem uma conta? faça seu login aqui",
        logout:"Sair",
        recuperar:"Recuperar Conta",
        repeatpassword:"Repita a senha!",
        esqueceusenha:"Esqueceu a senha?",
        invalid:{
          tooshort:"Muito curto!",
          toolong:"Muito longo!",
          googleaccount:"Ocorreu um problema ao fazer login via Google. Por favor, tente novamente mais tarde.",
          email:"E-mail inválido",
          Required:"Obrigatório",
          senha:"As senhas inseridas não correspondem. Por favor, verifique se as senhas digitadas são idênticas e tente novamente."
        }
      },
      auth:{
        toasts:{
          success: "Login efetuado com sucesso!",
        }
      },
      verifyemail:{
        success:"Solicitação de validação de email enviada com sucesso! Por favor, verifique sua caixa de entrada para confirmar seu endereço de email."
      },
      email:{
        valid:
        {
          success:"E-mail validado com sucesso! Obrigado por confirmar seu endereço de e-mail."
        }
      },
      userModal:{
        success: "Os dados do usuário foram atualizados.",
        edit:"Editar",
        verify:"Por favor, verifique seu e-mail e conclua o processo de validação.",
        verifybutton:"Verificar",
        add:"Adicionar",
        cancel:"Cancelar"
      },
      saldo:"Você acabou de receber ",
      signup:{
        toasts:{
          success: "Usuário criado com sucesso! Você já pode efetuar seu login.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        }
      },
      backendErrors:{
        ERR_NUMBER_NOPLAN_FAILED: "VOCÊ NÃO POSSUI NENHUM PLANO ATIVO",
ERR_NUMBER_INVALID_FAILED: "FORMATO DO NÚMERO INSERIDO É INVÁLIDO",
ERR_NUMBER_LIMIT_FAILED:" LIMITE DE NÚMEROS AUTORIZADOS NO SEU PLANO ATUAL FOI ATINGIDO.",
ERR_NUMBER_ALREADY_FAILED: "DESCULPE,ESTE NÚMERO JÁ ESTÁ CADASTRADO.",
        saldo:"Você acabou de perder ",
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_RECOVERYCODE_INVALID:"Por favor, clique em ''Esqueceu a senha'' e solicite um novo link no seu e-mail cadastrado.",
        ERR_USER_INVALID:"Usuário inválido",
        ERR_VERIFY_INVALID:"O código de validação inserido é inválido. Por favor, verifique se digitou corretamente o código fornecido e tente novamente.",
        ERR_Verify_time_INVALID:"Pedimos desculpas pela inconveniência, mas solicitamos que o cliente aguarde um momento antes de enviar uma nova solicitação de validação de email. Isso nos permitirá processar adequadamente a sua solicitação anterior e garantir uma experiência satisfatória. Agradecemos a compreensão e paciência do cliente.",
        ERR_Recoverytable_time_INVALID:"Aguarde alguns momentos para que possamos enviar o email de recuperação. Isso pode levar alguns minutos, portanto, pedimos que tenha um pouco de paciência.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de uma fila.",
      }
    },    
  },
};

export { messages };