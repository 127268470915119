import React, { useContext,useEffect,useReducer,useState } from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink ,useParams} from "react-router-dom";
import {Button} from "@material-ui/core";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import UserModal from "../../components/UserModal";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify"
import toastError from "../../errors/toastError"
const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 360,
	},
	fixedHeightPaperr: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "auto",
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
}))

const Dashboard = () => {
	const classes = useStyles()
    const { recoveryCode } = useParams();
	const { user } = useContext(AuthContext);
    const history = useHistory();
	const [loading, setLoading] = useState(false);
    useEffect(()=>{
       (async ()=>{ try{
await api.post("/verifycompletion",{recoveryCode})
toast.success(i18n.t("email.valid.success"))
setTimeout(async() => {history.push("/")},500);
        } catch (err) {
            toastError(err);
            setTimeout(async() => {history.push("/")},500);
        }})()
    },[])
	return (
		<div>
			     <h1>Confirmando seu email aguarde</h1>
		</div>
	)
}

export default Dashboard