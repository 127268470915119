import React, { useEffect, useReducer, useState ,useCallback} from "react";

import openSocket from "socket.io-client";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit, AddCircleOutline,} from "@material-ui/icons";
import PlanoModal from "../../components/PlanoModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_PLANOS") {
    const planos = action.payload;
    const newPlanos = [];

    planos.forEach((plano) => {
      const planoIndex = state.findIndex((q) => q.id === plano.id);
      if (planoIndex !== -1) {
        state[planoIndex] = plano;
      } else {
        newPlanos.push(plano);
      }
    });

    return [...state, ...newPlanos];
  }

  if (action.type === "UPDATE_PLANOS") {
    const plano = action.payload;
    const planoIndex = state.findIndex((u) => u.id === plano.id);

    if (planoIndex !== -1) {
      state[planoIndex] = plano;
      return [...state];
    } else {
      return [plano, ...state];
    }
  }

  if (action.type === "DELETE_PLANO") {
    const planoId = action.payload;
    const planoIndex = state.findIndex((q) => q.id === planoId);
    if (planoIndex !== -1) {
      state.splice(planoIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Planos = () => {
  const classes = useStyles();

  const [planos, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [planoModalOpen, setPlanoModalOpen] = useState(false);
  const [selectedPlano, setSelectedPlano] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/plano");
        dispatch({ type: "LOAD_PLANOS", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    const socket = openSocket(process.env.REACT_APP_BACKEND_URL, {query: {token}});
    socket.on("connect", () => {
      socket.emit("joinCompany")
    });

    socket.on("plano", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PLANOS", payload: data.plano });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_PLANO", payload: data.planoId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenPlanoModal = () => {
    setPlanoModalOpen(true);
    setSelectedPlano(null);
  };


  const handleClosePlanoModal = () => {
    setPlanoModalOpen(false);
    setSelectedPlano(null);
  };

  const handleEditPlano = (plano) => {
    setSelectedPlano(plano);
    setPlanoModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedPlano(null);
  };

  const handleDeletePlano = async (planoId) => {
    try {
      await api.delete(`/plano/${planoId}`);
      toast.success(i18n.t("Plano deleted successfully!"));
      dispatch({ type: "DELETE_PLANO", payload: planoId });
    } catch (err) {
      toastError(err);
    }
    setSelectedPlano(null);
  };
  const fetchPlanos = useCallback(async() => {
    try {
      const { data } = await api.get("/plano/");
      dispatch({ type: "LOAD_PLANOS", payload: data });
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, []);
  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedPlano &&
          `${i18n.t("planos.confirmationModal.deleteTitle")} ${
            selectedPlano.name
          }?`
        }
        open={confirmModalOpen}
        reload={fetchPlanos}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeletePlano(selectedPlano.id)}
      >
        {i18n.t("planos.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <PlanoModal
        open={planoModalOpen}
        onClose={handleClosePlanoModal}
        planoId={selectedPlano?.id}
        reload={fetchPlanos}
        onEdit={(res) => {
          if(res) {
              setTimeout(() => {
                // setPlanoModalOpen(true)
                // setSelectedPlano(res.id)
                handleEditPlano(res)
              }, 200)
          }
        }}
      />
      <MainHeader>
        <Title>{i18n.t("planos.title")}</Title>
        <MainHeaderButtonsWrapper>
        <Button
              variant="contained"
              color="primary"
              onClick={handleOpenPlanoModal}
            >
              <AddCircleOutline />
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("planos.table.name")}
              </TableCell>
              <TableCell align="center">
                Favoritos maximo
              </TableCell>
              <TableCell align="center">
                {i18n.t("planos.table.numbers")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("planos.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {planos.map((plano) => (
                <TableRow key={plano.id}>
                  <TableCell align="center">{plano.name}</TableCell>
                  <TableCell align="center">{plano.numbers+1}</TableCell>
                  <TableCell align="center">{plano.multidevice}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditPlano(plano)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Planos;