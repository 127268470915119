import React, { useState, useContext } from "react";
import jwt_decode from "jwt-decode";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Link,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { LockOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { maskPhoneNumber } from '../../utils';

// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t("login.invalid.tooshort"))
    .max(50, i18n.t("login.invalid.toolong"))
    .required(i18n.t("login.invalid.Required")),
  password: Yup.string()
    .min(5, i18n.t("login.invalid.tooshort"))
    .max(50, i18n.t("login.invalid.toolong"))
    .required(i18n.t("login.invalid.Required")),
  email: Yup.string()
    .email(i18n.t("login.invalid.email"))
    .required(i18n.t("login.invalid.Required")),
  number: Yup.string()
    .min(10, i18n.t("login.invalid.tooshort"))
    .max(50, i18n.t("login.invalid.toolong"))
    .required(i18n.t("login.invalid.Required")),
});

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const { handleLogingoogle } = useContext(AuthContext);

  const initialState = { name: "", email: "", password: "", number: "" };
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState(initialState);

  const handleSignUp = async (values) => {
    try {
      await api.post("/auth/signup", values);
      toast.success(i18n.t("signup.toasts.success"));
      history.push("/login");
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {/*<GoogleOAuthProvider clientId="810259892503-ls2cg23bm8p3iavll6fvg6a5koid5bj5.apps.googleusercontent.com">*/}
      <CssBaseline />
      <div className={classes.paper}>
        {/*<Avatar className={classes.avatar}>
					<LockOutlined />
				</Avatar>*/}
        <img
          src="https://painel.adscaler.com.br/logomyleadslite100.png"
          width={"350px"}
        />
        {/* <form className={classes.form} noValidate onSubmit={handleSignUp}> */}
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSignUp({
                ...values,
                number: values?.number.replace(/\D/g, ""),
              });
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    autoComplete="name"
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label={i18n.t("login.name")}
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    value={maskPhoneNumber(String(values.number))}
                    name="number"
                    onChange={(event) =>
                      setUser((user) => {
                        return {
                          ...user,
                          number: maskPhoneNumber(event.target.value),
                        };
                      })
                    }
                    keypress={(event) =>
                      setUser((user) => {
                        return {
                          ...user,
                          number: maskPhoneNumber(event.target.value),
                        };
                      })
                    }
                    label="Telefone de Contato"
                    error={touched.number && Boolean(errors.number)}
                    helperText={touched.number && errors.number}
                    autoComplete="number"
                    placeholder="EX. +55 (99) 12345-6789"
                    defaultvalue={"+55"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="password"
                    id="password"
                    autoComplete="current-password"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    label={i18n.t("login.password")}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((e) => !e)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {i18n.t("login.registror")}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    href="#"
                    variant="body2"
                    component={RouterLink}
                    to="/login"
                  >
                    {i18n.t("login.login")}
                  </Link>
                  {/*<GoogleLogin
  onSuccess={credentialResponse => {
    var {email,sub,name} = jwt_decode(credentialResponse.credential);
    
    handleLogingoogle({email,sub,name})
  }}
  onError={() => {
  toast.error(i18n.t("login.invalid.googleaccount"))
  }}
  useOneTap={true}
/>*/}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
      <Box mt={5}>ADScaler © 2023</Box>
      {/*</GoogleOAuthProvider>*/}
    </Container>
  );
};

export default SignUp;
