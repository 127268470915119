import React, { useContext,useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import {
  CssBaseline,
  Switch,
  FormGroup,
  FormControlLabel,
  makeStyles
} from "@material-ui/core";


const useStyles = makeStyles(() => ({
  switch: {
    margin: "2px",
    position: "absolute",
    right: "0",
  },
  visible: {
    display: "none",
  },
}));
const App = () => {
  const [locale, setLocale] = useState();

  const lightTheme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        primary: { main: "#1798e3" },
        login: { main: "#54575c" },
        secondary: { main: "#787b80" },
      },
    },
    locale
  );
  const darkTheme = createTheme(
    {
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              backgroundColor: "#080d14",
            }
          }
        }
      },
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#ffffff",
        },
      },
      palette: {
        primary: { main: "#1798e3" },
        secondary: { main: "#787b80" },
        login: { main: "#d0d3d9" },
        background: {
          default: "#080d14",
          paper: "#181d22",
        },
        text: {
          primary: "#ffffff",
          secondary: "#ffffff",
        },
      },
    },
    locale
  );
  const [theme, setTheme] = useState("light");
useEffect(()=>{
  const mql = window.matchMedia('(prefers-color-scheme: dark)');
  setTheme(mql.matches == true? "dark": "light");
},[])

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
    <Routes />
    <CssBaseline />
  </ThemeProvider>
  );
};

export default App;