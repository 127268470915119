import React, { useEffect, useReducer, useState ,useCallback} from "react";

import openSocket from "socket.io-client";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,TextField,FormControl,InputLabel,MenuItem,InputAdornment
} from "@material-ui/core";
import moment from "moment"
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit, AddCircleOutline,Search} from "@material-ui/icons";
import PlanoModal from "../../components/PlanoModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_SUGEST") {
    const sugests = action.payload;
    const newPlanos = [];

    sugests.forEach((sugest) => {
      const planoIndex = state.findIndex((q) => q.id === sugest.id);
      if (planoIndex !== -1) {
        state[planoIndex] = sugest;
      } else {
        newPlanos.push(sugest);
      }
    });

    return sugests;
  }

  if (action.type === "UPDATE_SUGEST") {
    const sugest = action.payload;
    const planoIndex = state.findIndex((u) => u.id === sugest.id);

    if (planoIndex !== -1) {
      state[planoIndex] = sugest;
      return [...state];
    } else {
      return [sugest, ...state];
    }
  }

  if (action.type === "DELETE_SUGEST") {
    const planoId = action.payload;
    const planoIndex = state.findIndex((q) => q.id === planoId);
    if (planoIndex !== -1) {
      state.splice(planoIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Sugests = () => {
  const classes = useStyles();

  const [sugests, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [planoModalOpen, setPlanoModalOpen] = useState(false);
  const [selectedPlano, setSelectedPlano] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
const [search,setsearch] = useState("")
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/gustavolindo",{params:{
          texto:search
        }});
        
        dispatch({ type: "LOAD_SUGEST", payload: data.Numberrs });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, [search]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    const socket = openSocket(process.env.REACT_APP_BACKEND_URL, {query: {token}});
    socket.on("connect", () => {
      socket.emit("joinCompany")
    });

    socket.on("sugest", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_SUGEST", payload: data.sugest });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_SUGEST", payload: data.planoId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenPlanoModal = () => {
    setPlanoModalOpen(true);
    setSelectedPlano(null);
  };


  const handleClosePlanoModal = () => {
    setPlanoModalOpen(false);
    setSelectedPlano(null);
  };

  const handleEditPlano = (sugest) => {
    setSelectedPlano(sugest);
    setPlanoModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedPlano(null);
  };

  const handleDeletePlano = async (planoId) => {
    try {
      await api.delete(`/sugest/${planoId}`);
      toast.success(i18n.t("Sugest deleted successfully!"));
      dispatch({ type: "DELETE_SUGEST", payload: planoId });
    } catch (err) {
      toastError(err);
    }
    setSelectedPlano(null);
  };
  const fetchPlanos = useCallback(async() => {
    try {
      const { data } = await api.get("/sugest/");
      dispatch({ type: "LOAD_SUGEST", payload: data });
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, []);
  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedPlano &&
          `${i18n.t("sugests.confirmationModal.deleteTitle")} ${
            selectedPlano.name
          }?`
        }
        open={confirmModalOpen}
        reload={fetchPlanos}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeletePlano(selectedPlano.id)}
      >
        {i18n.t("sugests.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <PlanoModal
        open={planoModalOpen}
        onClose={handleClosePlanoModal}
        planoId={selectedPlano?.id}
        reload={fetchPlanos}
        onEdit={(res) => {
          if(res) {
              setTimeout(() => {
                // setPlanoModalOpen(true)
                // setSelectedPlano(res.id)
                handleEditPlano(res)
              }, 200)
          }
        }}
      />
      <MainHeader>
        <Title>Sugestões</Title>
        <MainHeaderButtonsWrapper>
        <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={search}
            onChange={event => setsearch(event.target.value.toLowerCase())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
            <TableCell align="center">
                Nome
              </TableCell>
              <TableCell align="center">
                Email
              </TableCell>
              <TableCell align="center">
                Numero
              </TableCell>
              <TableCell align="center">
                 Nota
              </TableCell>
              <TableCell align="center">
                Sugestão
              </TableCell><TableCell align="center">
                Data
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {sugests.map((sugest) => (
                <TableRow key={sugest.id}>
                  <TableCell>{sugest.user.name}</TableCell>
                  <TableCell>{sugest.user.email}</TableCell>
                  <TableCell>{sugest.user.number}</TableCell>
                  <TableCell>{sugest.nota}</TableCell>
                  <TableCell>{sugest.message}</TableCell>
                  <TableCell>{moment(sugest.createdAt).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Sugests;