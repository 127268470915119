import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import jwt_decode from "jwt-decode";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Link
} from '@material-ui/core';
import { i18n } from "../../translate/i18n";
import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";


import { AuthContext } from "../../context/Auth/AuthContext";
import { GoogleOAuthProvider,GoogleLogin,useGoogleOneTapLogin } from '@react-oauth/google';
import { toast } from "react-toastify";
// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  olho:{
    color:theme.palette.login.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin ,handleLogingoogle} = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <Container component="main" maxWidth="xs">
     {/* <GoogleOAuthProvider clientId="810259892503-ls2cg23bm8p3iavll6fvg6a5koid5bj5.apps.googleusercontent.com">*/}
      <CssBaseline />
      <div className={classes.paper}>
        
        {/*<Avatar className={classes.avatar}>
          <LockOutlined />
  </Avatar>*/}
  <img src="https://painel.adscaler.com.br/logomyleadslite100.png" width={"350px"}/>
        <form className={classes.form} noValidate onSubmit={handlSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={user.email}
            onChange={handleChangeInput}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={i18n.t("login.password")}
            id="password"
            value={user.password}
            onChange={handleChangeInput}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((e) => !e)}
                  >
                    {showPassword ? <VisibilityOff className={classes.olho} /> : <Visibility className={classes.olho}/>}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {i18n.t("login.button")}
          </Button>
          <Grid container>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                component={RouterLink}
                to="/signup"
              >
                {i18n.t("login.registro")}
              </Link>
              <br/>
              <br/>
              <Link
                href="#"
                variant="body2"
                component={RouterLink}
                to="/forgotpd"
              >
                {i18n.t("login.esqueceusenha")}
              </Link>
              <br/>
              {/*<GoogleLogin
  onSuccess={credentialResponse => {
    var {email,sub,name} = jwt_decode(credentialResponse.credential);
    
    handleLogingoogle({email,sub,name})
  }}
  onError={() => {
  toast.error(i18n.t("login.invalid.googleaccount"))
  }}
  useOneTap={true}
/>*/}
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>ADScaler © 2023</Box>{/*</GoogleOAuthProvider>*/}
    </Container>
  );
};

export default Login;