import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton
  } from '@material-ui/core';
  import moment from "moment"

import { AirplayTwoTone, Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";


import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, i18n.t("login.invalid.tooshort"))
		.max(50, i18n.t("login.invalid.toolong"))
		.required(i18n.t("login.invalid.Required")),
	password: Yup.string().min(5, i18n.t("login.invalid.tooshort")).max(50, i18n.t("login.invalid.toolong")),
	number: Yup.string().min(10, i18n.t("login.invalid.tooshort")).max(14, i18n.t("login.invalid.toolong")).required(i18n.t("login.invalid.error")),
	email: Yup.string().email(i18n.t("login.invalid.email")).required(i18n.t("login.invalid.Required")),
});

const UserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		number: "",
		profile: "user"
	};

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedPlano,setSelectedPlano] = useState(null)

    const [planos,setPlano] = useState([])
	const [showPassword, setShowPassword] = useState(false);
const handleverifybutton = async () =>{
try{await api.post("/verifyemail",{email:user.email,language:i18n.language.split("-")[0]})
toast.success(i18n.t("verifyemail.success"))
} catch (err) {
	toastError(err);
}
}
	useEffect(() => {
		const fetchUser = async () => {
			const { data:dataplano } = await api.get(`/plano/`);
			setPlano(dataplano)
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setSelectedPlano(data.plano)
				setUser(prevState => {
					return { ...prevState, ...data,expire:moment(data.expire).format('YYYY-MM-DDTHH:mm'), };
				});
	
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {
		try {
			if (userId) {
				await api.put(`/users/${userId}`, {...values,selectedPlano});
			} else {
				await api.post("/users", {...values,selectedPlano});
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.edit")}`
						: `${i18n.t("userModal.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting ,values}) => (
						<Form>
							<DialogContent dividers>
{/*{user.VEF == false?							<p>{i18n.t("userModal.verify")}<Button 									onClick={handleverifybutton}
									color="primary"
									disabled={isSubmitting}
									variant="outlined"
									size="small"
					>{i18n.t("userModal.verifybutton")}</Button></p>:<></>}*/}
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("login.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										name="password"
										variant="outlined"
										margin="dense"
										label={i18n.t("login.password")}
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
										endAdornment: (
											<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword((e) => !e)}
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
											</InputAdornment>
										)
										}}
										fullWidth
									/>
								</div>
{user.profile == "admin" &&								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={"Email"}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>}
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("login.number")}
										name="number"
										error={touched.number && Boolean(errors.number)}
										helperText={touched.number && errors.number}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>
								<br/>
								{loggedInUser.profile == "admin" &&<><div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label="Tempo que termina"
										type="datetime-local"
										name="expire"
										InputLabelProps={{
										  shrink: true,
										}}
										error={touched.expire && Boolean(errors.expire)}
										helperText={touched.expire && errors.expire}
										variant="outlined"
										fullWidth
									/>
								</div>
								<FormControl fullWidth>
  <InputLabel >Plano</InputLabel>
  <Select
    label="Plano"
	value={selectedPlano}
	onChange={(selectedIds) => {setSelectedPlano(selectedIds.target.value);}}
  >
	<MenuItem value={null}>Sem plano</MenuItem>
{planos.map(element =>    <MenuItem value={element?.id}>{element?.name}</MenuItem>
	)}
  </Select>
</FormControl>
								
								
								</>
								}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										?`${i18n.t("userModal.edit")}`
										: `${i18n.t("userModal.add")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;